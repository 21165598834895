import React from 'react'
import PropTypes from 'prop-types'
import PreviewImage from './PreviewImage'

const GalleryEntry = ({ gridItems }) => (
 
    <div className="columns is-multiline">
          {gridItems.map((row,itemIndex) => (
          <section key={itemIndex} className="columns section gallery-entry">
            { row.text &&
              <div className="column poem is-2">              
                <p>{row.text}</p>
              </div>}
              {row.images.map((image,imageIndex) => (
                <div key={imageIndex} className="column">
                  <div
                    style={{
                      width: '350px',
                      height: '350px',
                    }}
                  >
                    <PreviewImage imageInfo={image} />
                    { image.info && <div className="image__overlay">
                        {image.info}
                      </div>}
                  </div>
                </div>
              ))}
          </section>
          ))}
    </div>
)

GalleryEntry.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        info: PropTypes.string
      })),
  })),
}

export default GalleryEntry
