import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GalleryEntry from '../components/GalleryEntry'

import SEO from "../components/SEO"

export const GalleryPageTemplate = ({ gallery }) => (

  <div className="content">
    <section className="section section--gradient">
      <GalleryEntry gridItems={gallery.rows} />
    </section>  
  </div>
)

GalleryPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  gallery: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.shape({
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          info: PropTypes.string
        })),
      })
    ),
  })
}

const GalleryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div>
      <SEO uniqueTitle={frontmatter.title} uniqueDescription={frontmatter.description} />
      <Layout image={frontmatter.image} title={frontmatter.title}>
        <GalleryPageTemplate
          image={frontmatter.image}
          title={frontmatter.title}
          description={frontmatter.description}
          gallery={frontmatter.gallery}
        />
      </Layout>
    </div>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default GalleryPage

export const galleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          rows {
            text
            images {
              image {
                childImageSharp {
                  fixed(width: 350, height: 350, quality: 80) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              info
            }
          }  
        }        
      }
    }
  }
`
